import { defineStore } from 'pinia'
import { STATUS_VALIDATED } from '~/const/client/status'

export const useClientsStore = defineStore('clients', {
    state: () => ({
        filters: {
            search: '',
            staff_ids: [],
            status: [STATUS_VALIDATED]
        }
    }),

    actions: {
        setFilter ({ key, value }) {
            this.filters[key] = value
        }
    }
})
