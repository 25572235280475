import { debounce } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { loadingMixin } from 'abcreche-ui'
import { useClientsStore } from '~/stores/clients'
import ClientRepository from '~/repositories/ClientRepository'

export default {
    mixins: [loadingMixin],

    data () {
        return {
            clients: [],
            clientsPagination: {},
            clientQueryParameters: {}
        }
    },

    computed: {
        ...mapState(useClientsStore, { clientFilters: 'filters' }),
        clientOptions () {
            return this.clients.map((s) => {
                return {
                    value: s.id,
                    label: s.name
                }
            })
        }
    },

    methods: {
        ...mapActions(useClientsStore, { setClientFilterInStore: 'setFilter' }),

        searchClients: debounce(function (page = 1, append = false) {
            this.load('clients.search')

            ClientRepository.index({
                ...this.clientFilters,
                ...this.clientQueryParameters,
                page
            })
                .then((response) => {
                    if (append) {
                        this.clients = this.clients.concat(response.data)
                    } else {
                        this.clients = response.data
                    }
                    this.clientsPagination = response.meta
                    this.done(null, 'clients.search')
                })
                .catch(error => this.pageError(error))
        }, 500),

        setClientsFilter (key, value, reset = false) {
            if (reset) {
                this.clients = []
            }
            this.setClientFilterInStore({ key, value })

            this.searchClients(1)
        }
    }
}
